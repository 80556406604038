<template>
  <v-app>
    <nav>
      <Drawer/>
    </nav>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from '@/components/Drawer.vue'

export default {
  name: 'App',
  components: {
    Drawer,
  },
  data: () => ({
    //
  }),
}
</script>
