
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

//The config we copied from firebase(Replace with your config)
const firebaseConfig = {
    apiKey: "AIzaSyAjwcUPHqVBbi9iagAekEOdupNo1tOA50w",
    authDomain: "scopri-di-piu-c257b.firebaseapp.com",
    databaseURL: "https://scopri-di-piu-c257b-default-rtdb.firebaseio.com",
    projectId: "scopri-di-piu-c257b",
    storageBucket: "scopri-di-piu-c257b.appspot.com",
    messagingSenderId: "795197821012",
    appId: "1:795197821012:web:c4a56c1157964d87c91dc1",
    measurementId: "G-CNNMFQF6X9"
  };

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export { app, auth }
