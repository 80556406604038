export default {
  "loader-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento Dati..."])},
  "global-ok-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
  "global-abort-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "global-save-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "global-cancel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
  "global-search-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])},
  "global-required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo richiesto"])},
  "global-max-length-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggiungimento numero massimo consentito"])},
  "global-invalid-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo non valido"])},
  "drawer-menu-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "drawer-menu-campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne"])},
  "drawer-menu-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
  "drawer-menu-sigin-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
  "drawer-menu-sigout-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
  "dashboard-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "welcome-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti su Scopri di più"])},
  "unauthorized-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti su Scopri di più"])},
  "campaign-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione campagne"])},
  "campaign-tab-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attive"])},
  "campaign-tab-inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non attive"])},
  "campaign-table-header-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
  "campaign-table-header-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "campaign-table-header-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
  "campaign-table-header-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
  "campaign-table-header-keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "campaign-table-header-action-click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landing"])},
  "campaign-table-header-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "campaign-table-header-speech-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo"])},
  "campaign-table-header-actions-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "campaign-table-header-actions-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
  "campaign-editor-title-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dati della campagna"])},
  "campaign-editor-title-insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci i dati della campagna"])},
  "campaign-btn-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aggiungi nuova campagna"])},
  "campaign-delete-message-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione"])},
  "campaign-delete-message-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler cancellare la campagna?"])},
  "statistic-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
  "statistic-tab-google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
  "statistic-tab-alexa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alexa"])},
  "statistic-table-header-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "statistic-table-header-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "statistic-table-header-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione"])},
  "statistic-table-header-occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occorrenze"])},
  "statistic-period-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inizio"])},
  "statistic-period-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fine"])},
  "statistic-period-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un periodo"])},
  "statistic-period-7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 7 giorni"])},
  "statistic-period-15days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 15 giorni"])},
  "statistic-period-30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 30 giorni"])},
  "statistic-period-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo mese"])},
  "statistic-period-3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 3 mesi"])},
  "statistic-period-6month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 6 mesi"])},
  "statistic-period-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo anno"])},
  "statistic-period-campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della campagna"])},
  "legend-action-invocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni volta che un utente invoca la skill"])},
  "legend-action-calls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero richieste del brand"])},
  "legend-action-nomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brand non trovato tramite keyword"])},
  "legend-action-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brand trovato tramite keyword"])},
  "legend-action-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'utente ha chiuso la skill"])},
  "legend-action-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si è verificato un errore durante la skill"])},
  "legend-action-speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero di chiamate da smart speaker"])},
  "legend-action-smartphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richiesta inviata direttamente dallo smartphone"])},
  "legend-action-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero di untenti che hanno invocato la skill"])},
  "legend-action-landing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni volta che l'utente apre la landing page"])},
  "legend-action-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni volta che l'utente scegli il link sullo smartphone"])},
  "legend-action-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni volta che l'utente riceve una notifica"])}
}