export default {
  "loader-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento Dati..."])},
  "global-ok-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
  "global-abort-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "global-save-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "global-cancel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
  "global-search-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])},
  "global-required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo richiesto"])},
  "global-max-length-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggiungimento numero massimo consentito"])},
  "global-invalid-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo non valido"])},
  "drawer-menu-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "drawer-menu-campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne"])},
  "drawer-menu-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
  "drawer-menu-sigin-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
  "drawer-menu-sigout-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
  "dashboard-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "campaign-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione campagne"])},
  "campaign-tab-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attive"])},
  "campaign-tab-inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non attive"])},
  "campaign-table-header-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
  "campaign-table-header-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "campaign-table-header-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
  "campaign-table-header-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
  "campaign-table-header-keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "campaign-table-header-action-click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landing"])},
  "campaign-table-header-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "campaign-table-header-speech-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo"])},
  "campaign-table-header-actions-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "campaign-table-header-actions-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
  "campaign-editor-title-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dati della campagna"])},
  "campaign-editor-title-insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci i dati della campagna"])},
  "campaign-btn-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aggiungi nuova campagna"])},
  "campaign-delete-message-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione"])},
  "campaign-delete-message-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler cancellare la campagna?"])},
  "statistic-title-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
  "statistic-tab-google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
  "statistic-tab-alexa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alexa"])},
  "statistic-table-header-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "statistic-table-header-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "statistic-table-header-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione"])},
  "statistic-table-header-occurrences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occorrenze"])},
  "statistic-period-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inizio"])},
  "statistic-period-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fine"])},
  "statistic-period-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un periodo"])},
  "statistic-period-7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 7 giorni"])},
  "statistic-period-15days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 15 giorni"])},
  "statistic-period-30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 30 giorni"])},
  "legend-action-direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La skill è stata invocata dallo smartphone"])},
  "legend-action-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'invocazione della skill"])},
  "legend-action-tour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richiesa fatta con il percorso completo"])},
  "legend-action-invocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esito positivo dell' invio della notifica allo smartphone dove è installata l'app alexa"])},
  "legend-action-nomatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagna non trovata con la parola chiave immessa"])},
  "legend-action-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagna trovata con la parola chiave immessa"])},
  "legend-action-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invio della notifica allo smartphone dove è installata l'app alexa"])},
  "legend-action-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utenti che hanno invocato la skill"])},
  "legend-action-landing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visualizzazioni della landing page su smartphone"])}
}