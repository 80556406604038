import { createI18n } from 'vue-i18n'

import it from "@/locales/it.json";
import en from "@/locales/en.json";


const i18n = createI18n({
  locale: "it",
  fallbackLocale: "en",
  messages: { it: it, en: en },
})
export default i18n