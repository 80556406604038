import { createStore } from 'vuex'
import { app, auth } from '@/firebase/config'
import {
    signInWithPopup,
    GoogleAuthProvider,
    signOut,
} from 'firebase/auth'
import {  getFirestore, 
    getDocs, 
    query, 
    collection,
    where, 
  } from "firebase/firestore";

const store = createStore({
    state: {
        //The user state will initially be null. After login, this state will be updated
        user: {
            loggedIn: false,
            data: null
        }
    },
    getters: {
        user(state){
          return state.user
        }
    },    
    mutations: {
        //Mutation to update the user state
        //Takes in two arguments, the state and the payload. When we call this mutation, the payload will be user object from firebase auth
        //When the user logs out, we call the mutation and the payload will be null
        
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        
    },
    actions: {

        async signIn(context){
            const response = await signInWithPopup(auth,new GoogleAuthProvider())
            if (response) {
                const db = getFirestore(app)
                const q = query(
                  collection(db, "users"),
                  where("active", "==", true),
                )
                const querySnap = await getDocs(q);
                if (querySnap.docs.find((doc) => doc.data().uid === response.user.uid)){
                    context.commit('SET_USER', response.user)
                } else {
                    await signOut(auth)
                    context.commit("SET_USER", null);
                }
                
            } else {
                throw new Error('login failed')
            }
        },
        async logout(context){
            await signOut(auth)
            context.commit('SET_USER', null)
        },
        async fetchUser(context ,user) {
            context.commit("SET_LOGGED_IN", user !== null)
            if (user || auth.currentUser) {
                context.commit("SET_USER", {
                displayName: auth.currentUser.displayName,
                email: auth.currentUser.email,
                picture: auth.currentUser.photoURL,
                accessToken: auth.currentUser.accessToken,
                uid: auth.currentUser.uid
              })
            } else {
              context.commit("SET_USER", null);
            }
        },
        // async checkAuthorization(context, user){

        //     const db = getFirestore(app)
        //     const q = query(
        //       collection(db, "users")
        //     )
        //     const querySnap = await getDocs(q);

        //     if (querySnap.find((doc) => doc.data().uid === user.uid)){
        //         context.commit('SET_USER', user)
        //     } else {
        //         await store.actions.logout(context)
        //         context.commit("SET_USER", null);
        //     }
            
        // }
    }
})

// export the store
export default store
