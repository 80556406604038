import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store"

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: () => import (/* webpackChunkName: "Welcome" */ '@/views/Welcome.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import (/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import (/* webpackChunkName: "Campains" */ '@/views/CampaignsManager.vue'),
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import (/* webpackChunkName: "Statistics" */ '@/views/StatisticsManager.vue'),
    },{
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import (/* webpackChunkName: "Unauthorized" */ '@/views/Unauthorized.vue'),

    }
]


const router = createRouter({
    history: createWebHistory(process. env. BASE_URL),
    routes
  })

router.beforeEach((to, from, next) => {
    //console.log("to.name", to.name, "from.name",from.name)
    if (to.name == 'welcome' && store.state.user == null) next({ name: 'welcome' })
    else if (to.name == 'welcome' && store.state.user != null) next({ name: 'dashboard' })
    else if (to.name !== 'campaigns' && store.state.user == null) next({ name: 'welcome' })
    else if (to.name !== 'statistics' && store.state.user == null) next({ name: 'welcome' })
    else next()
})


export default router      
