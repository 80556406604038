<template>
  <v-navigation-drawer
    v-model="drawer"
    clipped
    width=320 
    absolute permanent
  >
    <v-list v-if="user.loggedIn">
      <v-list-item
        :prepend-avatar="user.data.picture"
        :title="user.data.displayName"
        :subtitle="user.data.email"
      ></v-list-item>
    </v-list>
    <v-container
      class="pa-4">
      <v-row>
        <v-col v-if="user.loggedIn">
          <v-btn dense block title color="primary"
            @click="signOut"
            ><v-icon>mdi-logout-variant</v-icon>
            {{$t('drawer-menu-sigout-btn')}}
          </v-btn>
        </v-col>
        <v-col v-else>
          <v-btn dense block title color="primary"
            @click="signIn"
            ><v-icon>mdi-login-variant</v-icon>
            {{$t('drawer-menu-sigin-btn')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-list density="compact" nav v-if="user.loggedIn">
      <v-list-item v-for="item in items" :key="item.title" router :to="item.route"  :prepend-icon="item.icon" :title="item.title"/>
    </v-list>
  </v-navigation-drawer>
</template>
  
<script>
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { computed } from "vue";
  import { auth } from '@/firebase/config'
  export default {
    name: "DashboardComponent",
  
    setup() {
      const store = useStore()
      const router = useRouter()

      auth.onAuthStateChanged(user => {
        store.dispatch("fetchUser", user);
      });
  
      const user = computed(() => {
        return store.getters.user;
      });

      const signIn = async () => {
        await store.dispatch('signIn')
      }
      const signOut = async () => {
          await store.dispatch('logout')
          router.push('/')
      }
      return {user,signOut, signIn}
   },
   data() {
    return {
      drawer: true,
      items : []
    }
   },
   mounted() {
    this.items =[
      {title: this.$t("drawer-menu-dashboard"), icon: "mdi-view-dashboard", route: "/dashboard"},
      {title: this.$t("drawer-menu-campaigns"), icon: "mdi-chart-bubble", route: "/campaigns"},
      {title: this.$t("drawer-menu-statistics"), icon: "mdi-chart-line", route: "/statistics"},
    ]
    
   },
  };
</script>
  